



import { Component, Vue } from 'vue-property-decorator';
import { getLogoutRedirect } from '../store/rest.service';

@Component
export default class Logout extends Vue {

  private logoutError: Error|null = null;

  private mounted() {
    window.location.href = getLogoutRedirect();
 }
}
